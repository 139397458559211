@use 'styles/vapor' as vapor;

$button-sizing: (
  'base': (
    padding: 8px 16px,
    height: 40px,
  ),
  'small': (
    padding: 4px 12px,
    height: 32px,
  ),
  'outline-base': (
    padding: 6.5px 14.5px,
    height: 40px,
  ),
  'outline-small': (
    padding: 2.5px 10.5px,
    height: 32px,
  ),
);

$border-width: 1.5px;
$border-style: solid;
$transition-duration: 0.3s;
$transition-style: ease;
$icon-content-spacing: 6px;

.DeviceButton {
  @include vapor.typography('button', 'large');
  color: vapor.color('milk', 'base-100');
  display: inline-block;
  position: relative;
  background-color: vapor.color('titanium', 'base-100');
  box-sizing: border-box;
  width: fit-content;

  &.fullWidth {
    width: 100%;
    max-width: 100%;
  }  

  .content {
    gap: $icon-content-spacing;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &.sizeSmall {
    border-radius: vapor.border-radius('large');
    @include vapor.get-state($button-sizing, 'small');
  }
  
  &.sizeBase {
    border-radius: vapor.border-radius('extra-large');
    @include vapor.get-state($button-sizing, 'base');
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover { 
      background-color: vapor.color('titanium', 'base-90');
      transition: background-color $transition-duration $transition-style;
    }
  }

  &:focus-visible {
    background-color: vapor.color('titanium', 'base-75');
    transition: background-color $transition-duration $transition-style;
  }
  
  &:active {
    background-color: vapor.color('titanium', 'base-75');
  }

  .colorMask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: vapor.color('steel', 'base-75');
    border-radius: vapor.border-radius('extra-large');
  }

  &.color {
    @media (hover: hover) and (pointer: fine) {
      &:hover .colorMask {
        background-color: vapor.color('steel', 'base-50');
        transition: background-color $transition-duration $transition-style;
      }
    }
  
    &:focus-visible .colorMask {
      background-color: vapor.color('steel', 'base-30');
      transition: background-color $transition-duration $transition-style;
    }
  
    &:active .colorMask {
      background-color: vapor.color('steel', 'base-30');
    }
  }

  &:not(.color).outline {
    border: $border-width $border-style vapor.color('titanium', 'base-100');
    background-color: transparent;
    color: vapor.color('titanium', 'base-100');

    &.sizeSmall {
      @include vapor.get-state($button-sizing, 'outline-small');
    }
    
    &.sizeBase {
      @include vapor.get-state($button-sizing, 'outline-base');
    }
  
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        border-color: vapor.color('titanium', 'base-50');
        transition: border-color $transition-duration $transition-style;
      }
    }
  
    &:focus-visible {
      border-color: vapor.color('titanium', 'base-15');
      transition: border-color $transition-duration $transition-style;
    }
    
    &:active {
      border-color: vapor.color('titanium', 'base-15');
    }

    &:disabled {
      border-color: vapor.color('titanium', 'base-30');
      background-color: vapor.color('titanium', 'base-5');
      color: vapor.color('ink', 'base-50');
    }
  }
  
  &:disabled {
    background-color: vapor.color('titanium', 'base-50');
    color: vapor.color('milk', 'base-75');

    // required
    cursor: default;
    pointer-events: none;
  }

  // loading styles
  .hideContent {
    opacity: 0;
  }
  
  .loadingSpinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .lightSpinner {
    border-color: vapor.color('titanium', 'base-30');
    border-top-color: vapor.color('milk');
  }
  
  .darkSpinner {
    border-color: vapor.color('titanium', 'base-30');
    border-top-color: vapor.color('titanium', 'base-75');
  }

  .icon svg {
    @include vapor.icon-size('small');
  }
}
