@use 'styles/vapor' as vapor;

.Carousel {
  .innerCarousel {
    position: relative;
  }

  .leftArrow {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: -(vapor.spacing('base-loose'));
  }
  
  .rightArrow {
    position: absolute;
    top: 50%;
    transform: translate(50%, -50%);
    right: -(vapor.spacing('base-loose'));
  }

  .carouselSlide {
    width: auto;
    position: relative;
  }
}
