@use 'styles/vapor' as vapor;

$button-height: 8px;
$dot-width: 8px;
$max-dash-width: 48px;
$dash-clickable-height: 40px;

.Pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: vapor.spacing('tight');
  margin-top: vapor.spacing('base');
  margin-left: vapor.spacing('base');
  margin-right: vapor.spacing('base');

  .paginationButton {
    height: $button-height;
    background-color: black;
    opacity: 0.1;
    padding: vapor.spacing('none');
    display: block;
    cursor: pointer;

    &:hover, &:focus-visible {
      opacity: 0.3;
    }
  
    &.active {
      opacity: 1;
      cursor: default;
    }

    transition: opacity 0.3s ease;
  }

  &.styleDots {
    .paginationButton {
      width: $dot-width;
      border-radius: vapor.border-radius('round');
    }
  }
    
  &.styleDashes {
    .paginationItem {
      flex: 1;
      max-width: $max-dash-width;
    }

    .paginationButton {
      position: relative;
      width: 100%;
      border-radius: vapor.border-radius('extra-small');

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: $dash-clickable-height;
        top: -(vapor.spacing('base'));
        left: 0;
      }
    }
  }
}
