@use 'styles/vapor' as vapor;

.spinner {
  justify-content: start;
}

.spinnerCircle {
  border-color: vapor.color('titanium', 'base-30');
  border-top-color: vapor.color('titanium', 'base-100');
}
